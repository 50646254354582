import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import Analytics from './helpers/Analytics';
import AppRoutes from './routes';
import { fetchCurrentUser } from './actions/authActionCreators';

class App extends Component {
  componentDidMount() {
    Analytics.init();

    this.verifyAuth();
  }

  componentDidUpdate() {
    Analytics.setUser(this.props.user.property_manager_id);
  }

  verifyAuth() {
    this.props.fetchCurrentUser();
  }

  render() {
    return (
      <Router>
        <AppRoutes userId={this.props.user.id} />
      </Router>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, { fetchCurrentUser })(App);
